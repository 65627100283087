@tailwind base;

@tailwind components;

@tailwind utilities;

html{
  scroll-behavior: smooth;
}

.form-control label{
    @apply block mb-2 text-sm font-medium text-gray-900 dark:text-white;
}
.form-control input{
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500;
}

.btn {
  @apply text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800;
}

.dataTable .rdt_Table {
  @apply border-t border-l border-r rounded-md;
}

.dataTable .rdt_TableHeader {
  @apply w-full bg-transparent pl-0 font-bold;
}

div.dataTable > header{
  padding:14px 0px!important;
}

.dataTable .rdt_TableHeadRow {
  @apply w-full bg-gray-50 rounded-t-md font-semibold text-sm;
}

.dataTable .rdt_TableRow:last-child {
  @apply rounded-b-md;
}

.dataTable .rdt_TableRow:hover {
  @apply bg-yellow-100 border-b-gray-300 outline-none;
}

.rdt_TableCell {
  @apply text-sm;
}
.rdt_Pagination select{
  background: none; 
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 8px;
  padding-right: 1.5em;
}